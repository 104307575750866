import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'buttonCount', 'buttonForm2', 'buttonForm3', 'buttonForm4', 'buttonPreview', 'buttonRow',
    'htmlText', 'imageSrc', 'imageUrl', 'includeButtonRow', 'includeImagePreview'
  ];

  connect() {
    this.buttonConfigs = {
      bgColor: '#000000',
      buttonCount: parseInt(this.buttonCountTarget.value, 10),
      textColor: '#FFFFFF'
    };
    this.generateHtml();
  }

  addButtonConfig(buttonId) {
    this.buttonConfigs[buttonId] ||= {
      label: 'Label',
      url: '#'
    };
  }

  displayButtonForms(event) {
    const buttonCount = parseInt(event.target.value, 10);
    this.buttonConfigs.buttonCount = buttonCount;

    this.buttonForm2Target.hidden = !(buttonCount >= 2);
    this.buttonForm3Target.hidden = !(buttonCount >= 3);
    this.buttonForm4Target.hidden = !(buttonCount >= 4);

    this.generateHtml();
  }

  formatHtml(html) {
    let formattedHtml = html;
    formattedHtml = formattedHtml.replace(/\s+/g, ' ');
    formattedHtml = formattedHtml.replace(/;\s+/g, '; ');
    formattedHtml = formattedHtml.replace(/:\s+/g, ': ');
    formattedHtml = formattedHtml.replace(/(<\/?table)/g, '\n  $1');
    formattedHtml = formattedHtml.replace(/(<\/?tbody)/g, '\n    $1');
    formattedHtml = formattedHtml.replace(/(<\/?tr)/g, '\n      $1');
    formattedHtml = formattedHtml.replace(/(<td)/g, '\n        $1');
    formattedHtml = formattedHtml.replace(/(>)\s*(<\/td)/g, '$1\n        $2');
    formattedHtml = formattedHtml.replace(/(<a)/g, '\n          $1');
    formattedHtml = formattedHtml.replace(/(>)\s*(<\/a)/g, '$1\n          $2');
    formattedHtml = formattedHtml.replace(/(<span)/g, '\n            $1');
    formattedHtml = formattedHtml.replace(/(<img)/g, '\n            $1');
    formattedHtml = formattedHtml.replace(/(<\/center)/g, '\n$1');
    return formattedHtml.trim();
  }

  generateButton(label, url, buttonWidthPercentage) {
    const buttonStyle = `style="text-align: center; width: 100%; background-color: ${this.buttonConfigs.bgColor};
                         font-size: 26px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; text-decoration: none; padding-top: 18px;
                         padding-bottom: 18px; color: ${this.buttonConfigs.textColor}; border-radius: 3px; display: inline-block;"`;
    const button = `<td style="padding-bottom: 5px; width: ${buttonWidthPercentage}%;"><a ${buttonStyle} href="${url}" target="_blank" rel="noopener">
                    <span>${label}</span></a></td>`;
    return button;
  }

  generateButtonRow(buttonCount) {
    const buttonWidthPercentage = (101 / buttonCount) - 1;
    const buttons = [];
    for (let i = 1; i <= buttonCount; i += 1) {
      this.addButtonConfig(`button-${i}`);
      buttons.push(this.generateButton(this.buttonConfigs[`button-${i}`].label, this.buttonConfigs[`button-${i}`].url, buttonWidthPercentage));
    }
    return `<tr>${buttons.join('<td style="width: 1%;">&nbsp;</td>')}</tr>`;
  }

  generateHtml() {
    const buttonCount = this.buttonConfigs.buttonCount;
    const buttonRow = this.includeButtonRowTarget.checked ? this.generateButtonRow(buttonCount) : '';
    const imageRow = this.generateImageRow();

    this.buttonPreviewTarget.innerHTML = this.includeImagePreviewTarget.checked ? this.generateTable(buttonRow, imageRow) : this.generateTable(buttonRow);
    this.htmlTextTarget.value = this.formatHtml(`<center>${this.generateTable(buttonRow, imageRow)}</center>`);
  }

  generateImageRow() {
    const colspanValue = this.includeButtonRowTarget.checked ? (this.buttonConfigs.buttonCount * 2) - 1 : 1;
    const imageUrl = this.imageUrlTarget.value ? this.imageUrlTarget.value : '#';
    const imageSrc = this.imageSrcTarget.value ? this.imageSrcTarget.value : '#';
    return `<tr>
              <td colspan="${colspanValue}">
                <a href="${imageUrl}" target="_blank" rel="noopener">
                  <img src="${imageSrc}" style="height: auto; display: block; border: 0; max-width: 800px;">
                </a>
              </td>
            </tr>`;
  }

  generateTable(buttonRow, imageRow = '') {
    return `<table style="width: 100%; max-width: 800px;" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                ${buttonRow}
                ${imageRow}
              </tbody>
            </table>`;
  }

  toggleButtonRow(event) {
    this.buttonRowTarget.hidden = !event.target.checked;
    this.generateHtml();
  }

  updateButton(event) {
    const buttonId = event.target.dataset.buttonId;
    const buttonAttribute = event.target.dataset.buttonAttribute;
    this.buttonConfigs[buttonId][buttonAttribute] = event.target.value;
    this.generateHtml();
  }

  updateButtonStyle(event) {
    const buttonAttribute = event.target.dataset.buttonAttribute;
    this.buttonConfigs[buttonAttribute] = event.target.value.replace(/(^[a-fA-F0-9]{6}$)/, '#$1');
    this.generateHtml();
  }

  validUrl(url) {
    const urlRegex = /^((http|https):\/\/)(([a-zA-Z0-9\-\.]*)\.)?([a-zA-Z0-9\-]+)\.([a-zA-Z]{2,5})(\/)?[\-\/\w.]*/;
    return urlRegex.test(url);
  }

  validateUrl(event) {
    const input = event.target;
    if (this.validUrl(input.value) || !input.value) {
      input.classList.remove('is-invalid');
    } else {
      input.classList.add('is-invalid');
      let errorMessage = input.nextElementSibling;
      if (!errorMessage || !errorMessage.classList.contains('invalid-feedback')) {
        errorMessage = document.createElement('div');
        errorMessage.classList.add('invalid-feedback');
        input.parentNode.insertBefore(errorMessage, input.nextSibling);
      }
      errorMessage.textContent = 'Please enter a valid URL';
    }
  }
}
