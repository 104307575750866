import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['copyContent', 'tinymce']

  replaceContent() {
    const editorInstance = tinymce.get(this.tinymceTarget.id);

    if (editorInstance.isHidden()) {
      this.tinymceTarget.value = this.copyContentTarget.value;
    } else {
      editorInstance.setContent(this.copyContentTarget.value);
    }
  }

  toggle() {
    tinymce.execCommand('mceToggleEditor', false, `${this.tinymceTarget.id}`);
  }
}
