import { Controller } from 'stimulus';
import { createConsumer } from '@rails/actioncable';
import consumer from '../../channels/consumer';


export default class extends Controller {
  static targets = ['aspectRatioWarning', 'audioIcon', 'audioWarningIcon', 'audioWarningIconText', 'clerkingWarning', 'container', 'eventOverOverlay',
    'eventOverOverlayText', 'generalWarning', 'lowAudioBitrateWarning', 'lowFpsWarning', 'lowVideoBitrateWarning', 'muteAudio', 'muteVideo',
    'operator', 'operatorText', 'spinner', 'startStreamButton', 'startTimeTesting', 'video', 'videoIcon', 'videoWarningIcon', 'videoWarningIconText',
    'viewerCount'];

  connect() {
    this.startTime = new Date(this.videoTarget.getAttribute('data-start-time'));
    this.eventId = this.videoTarget.getAttribute('data-stream-name');
    document.getElementById('start-monitor-overlay').addEventListener('click', () => {
      if (new Date() > this.startTime) {
        this.getActionCableInfo();
      } else {
        this.startVideoStreamInterval = setInterval(() => {
          if (new Date() > this.startTime) {
            this.getActionCableInfo();
            clearInterval(this.startVideoStreamInterval);
          }
        }, 60000);
      }
      document.getElementById('start-monitor-overlay').classList.add('d-none');
    });
  }

  formatTime(seconds) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    let formattedTime = '';

    if (hrs > 0) {
      formattedTime += `${hrs}h `;
    }
    if (mins > 0 || hrs > 0) {
      formattedTime += `${mins}m `;
    }
    if (mins < 15 && hrs < 1) {
      formattedTime += `${secs}s`;
    }

    return formattedTime.trim();
  }

  getActionCableInfo() {
    this.initializeVariables();
    this.startStreamButtonTarget.remove();
    const monitors = this;
    const consolesConsumer = createConsumer(`wss://${this.videoTarget.getAttribute('data-console-host')}/cable`);
    this.operatorSubscription = consolesConsumer.subscriptions.create({ channel: 'MonitorChannel', event_id: monitors.eventId }, {
      received(data) {
        console.log(data);
        switch (data.action) {
          case 'set_operator_status':
            monitors.parseOperatorStatus(data.connected);
            break;
          case 'initialize':
          case 'set_broadcast_options':
            if ('connected' in data) {
              monitors.parseOperatorStatus(data.connected);
            }
            if (data.broadcast_options.dual_broadcast !== monitors.dualBroadcast) {
              monitors.parseBroadcastOptions(data.broadcast_options);
            }
            if (data.calculated_end_time) {
              monitors.parseCalculatedEndTime(data.calculated_end_time);
            }
            break;
          case 'calculated_end_time':
            monitors.parseCalculatedEndTime(data.calculated_end_time);
            break;
          default:
            break;
        }
      }
    });
  }

  initializeVariables() {
    this.audioBitrateTime = new Date();
    this.audioWarningDelayTime = this.videoTarget.getAttribute('data-audio-warning-delay-time');
    this.audioWarningTrack = document.getElementById('audio-warning-track');
    this.audioWarningTrackLastPlayed = new Date();
    this.broadcastEventCallback = () => { };
    this.dualBroadcast = '';
    this.clerkingData = this.videoTarget.getAttribute('data-clerking-data');
    this.eventOverNotification = true;
    this.fpsTime = new Date();
    this.lastAudioTime = new Date();
    this.lastOperatorTime = new Date();
    this.lastVideoTime = new Date();
    this.muteAudio = false;
    this.muteVideo = false;
    this.statsCallback = () => { };
    this.streamAccountIdValue = this.videoTarget.getAttribute('data-account-id');
    this.trackCallback = () => { };
    this.videoBitrateTime = new Date();
    this.videoResolutionTime = new Date();
    this.videoWarningDelayTime = this.videoTarget.getAttribute('data-video-warning-delay-time');
  }

  millicastRateLimitNotification(error) {
    const url = '/admin/monitors/millicast_rate_limit_notification';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify(error)
    })
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log('Error:', response);
      });
  }

  mute(event) {
    if (event.target.getAttribute('data-type') === 'audio') {
      this.muteAudio = !this.muteAudio;
      event.target.innerHTML = this.muteAudio ? 'Unmute Audio Warnings' : 'Mute Audio Warnings';
    } else {
      this.muteVideo = !this.muteVideo;
      event.target.innerHTML = this.muteVideo ? 'Unmute Video Warnings' : 'Mute Video Warnings';
    }
  }

  parseAudioBitrate() {
    const displayAudioBitrateWarning = (Math.round((new Date() - this.audioBitrateTime) / 1000)) > this.audioWarningDelayTime;

    if (this.muteAudio || !this.eventOverOverlayTarget.classList.contains('d-none') || !displayAudioBitrateWarning) {
      this.lowAudioBitrateWarningTarget.classList.add('d-none');
      return;
    }

    if (typeof this.audioBitrate === 'undefined') {
      this.lowAudioBitrateWarningTarget.innerHTML = 'Low Audio Bitrate.';
      this.lowAudioBitrateWarningTarget.classList.remove('d-none');
    } else if (this.audioBitrate < 3000) {
      this.lowAudioBitrateWarningTarget.innerHTML = `Audio: ${Math.round(parseInt(this.audioBitrate, 10) / 1000)} kbps`;
      this.lowAudioBitrateWarningTarget.classList.remove('d-none');
    } else {
      this.lowAudioBitrateWarningTarget.classList.add('d-none');
      this.audioBitrateTime = new Date();
    }
  }

  parseBroadcastOptions(broadcastOptions) {
    this.dualBroadcast = broadcastOptions.dual_broadcast;
    this.stopStream();
    if (this.dualBroadcast === 'disabled') {
      this.startStream(this.eventId, 'Audio_Video');
    } else {
      this.startStream(`${this.eventId}_video`, 'Video');
      this.startStream(`${this.eventId}_audio`, 'Audio');
    }
  }

  parseCalculatedEndTime(calculatedEndTime) {
    this.calculatedEndTime = new Date(calculatedEndTime);
    if (this.calculatedEndTime < new Date() && this.eventOverNotification) {
      this.eventOverOverlayTarget.classList.remove('d-none');
      this.eventOverOverlayTextTarget.classList.remove('d-none');
      this.clerkingWarningTarget.classList.add('d-none');
      this.operatorTarget.classList.add('d-none');
    } else {
      this.eventOverOverlayTarget.classList.add('d-none');
      this.eventOverOverlayTextTarget.classList.add('d-none');
    }
  }

  parseFps(fps) {
    const displayFpsWarning = (Math.round((new Date() - this.fpsTime) / 1000)) > this.videoWarningDelayTime;

    if (this.muteVideo || !this.eventOverOverlayTarget.classList.contains('d-none') || !displayFpsWarning) {
      this.lowFpsWarningTarget.classList.add('d-none');
      return;
    }

    if (typeof fps === 'undefined') {
      this.lowFpsWarningTarget.innerHTML = 'Low FPS.';
      this.lowFpsWarningTarget.classList.remove('d-none');
    } else if (fps < 10) {
      this.lowFpsWarningTarget.innerHTML = `FPS: ${fps}`;
      this.lowFpsWarningTarget.classList.remove('d-none');
    } else {
      this.lowFpsWarningTarget.classList.add('d-none');
      this.fpsTime = new Date();
    }
  }

  parseOperatorStatus(operatorConnected) {
    this.operatorConnected = operatorConnected;
    if (this.operatorConnected || !this.eventOverOverlayTarget.classList.contains('d-none')) {
      this.operatorTarget.classList.add('d-none');
      this.operatorTextTarget.innerHTML = '';
      this.lastOperatorTime = new Date();
      if (this.operatorInterval) {
        clearInterval(this.operatorInterval);
      }
    } else if (new Date() > this.startTime) {
      this.operatorTarget.classList.remove('d-none');
      this.operatorInterval = setInterval(() => {
        this.operatorTextTarget.innerHTML = this.formatTime(Math.round((new Date() - new Date(this.lastOperatorTime)) / 1000));
      }, 1000);
    }
  }

  parseVideoBitrate(videoBitrate) {
    const displayVideoBitrateWarning = (Math.round((new Date() - this.videoBitrateTime) / 1000)) > this.videoWarningDelayTime;

    if (this.muteVideo || !this.eventOverOverlayTarget.classList.contains('d-none') || !displayVideoBitrateWarning) {
      this.lowVideoBitrateWarningTarget.classList.add('d-none');
      return;
    }

    if (typeof videoBitrate === 'undefined') {
      this.lowVideoBitrateWarningTarget.innerHTML = 'Low Video Bitrate';
      this.lowVideoBitrateWarningTarget.classList.remove('d-none');
    } else if (videoBitrate < 500000) {
      this.lowVideoBitrateWarningTarget.innerHTML = `Video: ${Math.round(parseInt(videoBitrate, 10) / 1000)} kbps`;
      this.lowVideoBitrateWarningTarget.classList.remove('d-none');
    } else {
      this.lowVideoBitrateWarningTarget.classList.add('d-none');
      this.videoBitrateTime = new Date();
    }
  }

  parseVideoResolution() {
    const displayVideoResolutionWarning = ((new Date() - this.videoResolutionTime) / 1000) > this.videoWarningDelayTime;

    if (this.muteVideo || !this.eventOverOverlayTarget.classList.contains('d-none') || !displayVideoResolutionWarning) {
      this.aspectRatioWarningTarget.classList.add('d-none');
      return;
    }

    const width = this.videoTarget.videoWidth;
    const height = this.videoTarget.videoHeight;
    this.videoResolution = { width, height };

    const aspectRatio = width / height;
    this.is16by9 = Math.abs(aspectRatio - (16 / 9)) < 0.01; // Allow a small error margin

    if (width >= 640 && height >= 360 && this.is16by9) {
      this.videoResolutionTime = new Date();
      this.aspectRatioWarningTarget.classList.add('d-none');
    } else {
      this.aspectRatioWarningTarget.innerHTML = `Resolution: ${width}x${height}`;
      this.aspectRatioWarningTarget.classList.remove('d-none');
    }
  }

  playAudioWarning() {
    if ((new Date() - new Date(this.audioWarningTrack.getAttribute('data-last-played'))) > 3000) {
      this.audioWarningTrack.play();
    }
  }

  setAudioVideoListeners(stream, type) {
    this.audioWarningPlayedCallback = () => {
      this.audioWarningTrack.setAttribute('data-last-played', new Date().toString());
    };

    this.audioWarningTrack.removeEventListener('ended', this.audioWarningPlayedCallback);
    this.audioWarningTrack.addEventListener('ended', this.audioWarningPlayedCallback);


    stream.webRTCPeer.initStats();
    stream.webRTCPeer.off('stats', this.statsCallback);
    this.statsCallback = (stats) => {
      if (type.includes('Audio')) {
        this.audioBitrate = stats.audio.inbounds[0].bitrate;
        this.parseAudioBitrate();
        this.updateAudioWarnings();
      }
      if (type.includes('Video')) {
        const fps = stats.video.inbounds[0].framesPerSecond;
        // Millicast sdk appears to be sending in bytes per second, so we need to convert to bits per second
        // See https://github.com/millicast/millicast-sdk/pull/339/commits/77de84eeb9dcf3fce15fab2b811b49c9355e946c#r1560469186
        const videoBitrate = (stats.video.inbounds[0].bitrate * 8);
        this.parseFps(fps);
        this.parseVideoResolution();
        this.parseVideoBitrate(videoBitrate);
        this.updateVideoWarnings(fps, videoBitrate);
      }
    };

    stream.webRTCPeer.on('stats', this.statsCallback);

    if (type.includes('Video') && !this.calculatedEndTimeInterval) {
      this.calculatedEndTimeInterval = setInterval(() => {
        this.calculatedEndTime = this.operatorSubscription.send({ action: 'calculated_end_time' });
      }, 900000);
    }

    if (type.includes('Audio')) {
      stream.webRTCPeer.off('track', this.trackCallback);
      this.trackCallback = (event) => {
        if (event.track.kind === 'audio') {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const source = audioContext.createMediaStreamSource(new MediaStream([event.track]));
          source.connect(analyser);
          analyser.fftSize = 1024;
          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          this.audioLevelInterval = setInterval(() => {
            analyser.getByteTimeDomainData(dataArray);
            let sum = 0;
            for (let i = 0; i < bufferLength; i++) {
              const v = (dataArray[i] - 128) / 128.0;
              sum += v * v;
            }
            const rms = Math.sqrt(sum / bufferLength);
            this.audioLevel = rms;
          }, 1000);
        }
      };
      stream.webRTCPeer.on('track', this.trackCallback);
    }
    const monitors = this;
    if (this.clerkingData === 'true') {
      this.clerkingSubscription = consumer.subscriptions.create({ channel: 'MonitorChannel', event_id: monitors.eventId }, {
        received(data) {
          console.log(data);
          if (data.clerking_warning && monitors.eventOverOverlayTarget.classList.contains('d-none')) {
            monitors.clerkingWarningTarget.innerHTML = 'No clerking data';
            monitors.clerkingWarningTarget.classList.remove('d-none');
          } else {
            monitors.clerkingWarningTarget.classList.add('d-none');
          }
        }
      });
      monitors.clerkingInterval = setInterval(() => {
        if (new Date() > (new Date(this.startTime.getTime() + 1500000))) {
          monitors.clerkingSubscription.send({ action: 'clerking_data_received_time' });
        }
      }, 300000);
    }
  }

  setBroadcastListeners(stream, type) {
    this.broadcastEventCallback = (event) => {
      const { name, data } = event;
      switch (name) {
        case 'active':
          this.setAudioVideoListeners(stream, type);
          this.videoTarget.setAttribute('data-active', true);
          this.generalWarningTarget.classList.add('d-none');
          this.spinnerTarget.classList.add('d-none');
          this.muteAudio = false;
          this.muteAudioTarget.innerHTML = 'Mute Audio Warnings';
          this.muteVideo = false;
          this.muteVideoTarget.innerHTML = 'Mute Video Warnings';
          this.videoTarget.classList.remove('d-none');
          break;
        case 'inactive':
          if (type.includes('Video')) {
            this.lastOperatorTime = new Date();
            this.videoTarget.setAttribute('data-active', false);
            this.muteAudio = true;
            this.muteAudioTarget.innerHTML = 'Unmute Audio Warnings';
            this.muteVideo = true;
            this.muteVideoTarget.innerHTML = 'Unmute Video';
            this.generalWarningTarget.innerHTML = 'Stream not being published';
            this.generalWarningTarget.classList.remove('d-none');
            this.videoTarget.classList.add('d-none');
            this.spinnerTarget.classList.remove('d-none');
          } else {
            this.generalWarningTarget.innerHTML = 'No audio stream';
            this.generalWarningTarget.classList.remove('d-none');
          }
          break;
        case 'viewercount':
          this.viewerCountTarget.innerHTML = data.viewercount;
          break;
        default:
          console.log(data);
          this.generalWarningTarget.innerHTML = name + data;
          break;
      }
    };

    stream.off('broadcastEvent', this.broadcastEventCallback);
    stream.on('broadcastEvent', this.broadcastEventCallback);
  }

  async startStream(streamName, type) {
    this.spinnerTarget.classList.remove('d-none');
    this.tokenGenerator = () => millicast.Director.getSubscriber({
      streamName: streamName,
      streamAccountId: this.streamAccountIdValue
    });
    if (type === 'Audio') {
      this.audioStream = new millicast.View(streamName, this.tokenGenerator);
      this.setBroadcastListeners(this.audioStream, type);
      this.audioStream.connect({ events: ['active', 'inactive', 'stopped', 'vad', 'layers', 'migrate', 'viewercount'] }).then(() => {
        this.generalWarningTarget.classList.add('d-none');
        this.spinnerTarget.classList.add('d-none');
      }).catch((error) => {
        this.lowAudioBitrateWarningTarget.classList.remove('d-none');
        this.lowAudioBitrateWarningTarget.innerHTML = 'No Audio Stream';
        this.audioWarningIconTarget.classList.add('d-none');
        this.audioIconTarget.classList.add('d-none');
        console.log('error:', error);
        if (error.message.includes('Request limited')) {
          this.millicastRateLimitNotification({ message: error.message });
        }
      });
    } else {
      this.videoStream = new millicast.View(streamName, this.tokenGenerator, this.videoTarget);
      this.setBroadcastListeners(this.videoStream, type);
      this.videoStream.connect({ events: ['active', 'inactive', 'stopped', 'vad', 'layers', 'migrate', 'viewercount'] }).then(() => {
        this.generalWarningTarget.classList.add('d-none');
        this.spinnerTarget.classList.add('d-none');
      }).catch((error) => {
        this.generalWarningTarget.classList.remove('d-none');
        this.generalWarningTarget.innerHTML = 'Stream not being published';
        console.log('error:', error);
        if (error.message.includes('Request limited')) {
          this.millicastRateLimitNotification({ message: error.message });
        }
      });
    }
  }

  stopEventInterval() {
    this.eventOverOverlayTarget.classList.add('d-none');
    this.eventOverOverlayTextTarget.classList.add('d-none');
    this.eventOverNotification = false;
    if (this.calculatedEndTimeInterval) {
      clearInterval(this.calculatedEndTimeInterval);
    }
  }

  stopMonitoring() {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const excludedIds = params.get('exclude_id') ? params.get('exclude_id').split(',') : [];
    if (!excludedIds.includes(this.eventId)) {
      excludedIds.push(this.eventId);
      excludedIds.forEach((id) => {
        params.append('exclude_id[]', id);
      });
      window.history.pushState(null, '', url.toString());
    }
    this.stopStream();
    if (this.operatorSubscription) {
      this.operatorSubscription.unsubscribe();
    }
    this.containerTarget.remove();
  }

  stopStream() {
    if (this.clerkingInterval) {
      clearInterval(this.clerkingInterval);
    }
    if (this.startVideoStreamInterval) {
      clearInterval(this.startVideoStreamInterval);
    }
    if (this.videoStream) {
      this.videoStream.stop();
    }
    if (this.audioStream) {
      this.audioStream.stop();
    }
    if (this.audioLevelInterval) {
      clearInterval(this.audioLevelInterval);
    }
    if (this.calculatedEndTimeInterval) {
      clearInterval(this.calculatedEndTimeInterval);
    }
  }

  updateAudioWarnings() {
    const timeSinceLastAudio = Math.round((new Date() - this.lastAudioTime) / 1000);
    const displayAudioWarnings = timeSinceLastAudio > this.audioWarningDelayTime;

    if (this.muteAudio || !this.eventOverOverlayTarget.classList.contains('d-none')) {
      this.audioWarningIconTarget.classList.add('d-none');
      this.audioIconTarget.classList.add('d-none');
      return;
    }

    if (!displayAudioWarnings) {
      this.audioWarningIconTarget.classList.add('d-none');
      this.audioIconTarget.classList.remove('d-none');
      return;
    }

    if (this.audioBitrate < 1000 || this.audioLevel < 0.009) {
      this.playAudioWarning();
      this.audioIconTarget.classList.add('d-none');
      this.audioWarningIconTarget.classList.remove('d-none');
      this.audioWarningIconTextTarget.innerHTML = this.formatTime(timeSinceLastAudio);
    } else {
      this.audioWarningIconTarget.classList.add('d-none');
      this.audioIconTarget.classList.remove('d-none');
      this.lastAudioTime = new Date();
    }
  }

  updateVideoWarnings(fps, videoBitrate) {
    const timeSinceLastVideo = Math.round((new Date() - this.lastVideoTime) / 1000);
    const displayVideoWarnings = timeSinceLastVideo > this.videoWarningDelayTime;

    if (this.muteVideo || !this.eventOverOverlayTarget.classList.contains('d-none')) {
      this.videoWarningIconTarget.classList.add('d-none');
      this.videoIconTarget.classList.add('d-none');
      return;
    }

    if (!displayVideoWarnings) {
      this.videoWarningIconTarget.classList.add('d-none');
      this.videoIconTarget.classList.remove('d-none');
      return;
    }

    if ((videoBitrate < 500000) || (fps < 10) || !this.is16by9) {
      this.videoIconTarget.classList.add('d-none');
      this.videoWarningIconTarget.classList.remove('d-none');
      this.playAudioWarning();
      this.videoWarningIconTextTarget.innerHTML = this.formatTime(timeSinceLastVideo);
    } else {
      this.videoWarningIconTarget.classList.add('d-none');
      this.videoIconTarget.classList.remove('d-none');
      this.lastVideoTime = new Date();
    }
  }
}
