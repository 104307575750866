/**
 * Class for manipulating CSS strings.
 * @class CssManipulator
 */
class CssManipulator {
  /**
   * Find and return a given property's value of a given rule within a CSS string
   * @param {string} cssString - The CSS string to search in
   * @param {string} selector - The CSS selector to find
   * @param {[string]} properties - Array of CSS properties to find. Matches on the first one found
   * @returns {string|null} The value of the property or null if not found
   */
  static findCssPropertyValue(cssString, selector, properties) {
    const ruleMatch = this.findRule(cssString, selector);
    if (ruleMatch) {
      const [, , currentRule] = ruleMatch;
      const propertyMatch = this.findProperty(currentRule, properties);
      if (propertyMatch) {
        const [, , , propertyValue] = propertyMatch;
        return propertyValue;
      }
    }
    return null;
  }

  /**
   * Remove a property from a css rule within a css string
   * @param {string} cssString - The CSS string to search in
   * @param {string} selector - The CSS selector to remove from
   * @param {[string]} properties - Array of CSS properties to remove. Removes the first one found
   * @returns {string|null} The modified CSS string or null if not found
   */
  static removeCss(cssString, selector, properties) {
    const ruleMatch = this.findRule(cssString, selector);
    let result = cssString;
    if (ruleMatch) {
      const [, beforeRule, currentRule, afterRule] = ruleMatch;
      if (this.findProperty(currentRule, properties)) {
        const newRule = this.removeProperty(currentRule, properties);
        if (this.isRuleEmpty(newRule)) {
          result = `${beforeRule.trim()} ${afterRule.trim()}`;
        } else {
          result = `${beforeRule.trim()} ${newRule} ${afterRule.trim()}`;
        }
      }
    }
    return result.trim();
  }

  /**
   * Update a property value of a css rule within a css string. If rule/property not found, inserts one using the first property from properties param
   * @param {string} cssString - The CSS string to modify
   * @param {string} selector - The CSS selector to update
   * @param {[string]} properties - Array of CSS properties to update. Updates the first one found
   * @param {string} newValue - The new value for the property
   * @returns {string} Modified CSS string
   */
  static updateCss(cssString, selector, properties, newValue, important = false) {
    const ruleMatch = CssManipulator.findRule(cssString, selector);
    let result;
    if (ruleMatch) {
      const [, beforeRule, currentRule, afterRule] = ruleMatch;
      const newRule = this.updatePropertyValue(currentRule, properties, newValue, important);
      result = `${beforeRule.trim()} ${newRule.trim()} ${afterRule.trim()}`.trim();
    } else {
      result = this.insertRule(cssString, selector, properties[0], newValue, important);
    }
    return result;
  }

  /**
   * Find a property + value in a css rule string
   * @param {string} cssRuleString - The CSS rule string to search in
   * @param {[string]} properties - Array of CSS properties to find. Matches on the first one found
   * @returns {Array|null} Array with groups: [match, before, property, value, ending, after] or null if not found
   */
  static findProperty(cssRuleString, properties) {
    const regex = new RegExp(
      `([^]*)(${properties.join('|')})\\s*:\\s*([^;\\s!]*)(\\s*!important;|;)([^]*)`
    );
    return cssRuleString.match(regex);
  }

  /**
   * Find a css rule with the selector within a css string
   * @param {string} cssString - The CSS string to search in
   * @param {string} selector - The CSS selector to find
   * @returns {Array|null} Array with groups: [match, before, rule, after] or null if not found
   */
  static findRule(cssString, selector) {
    const regex = new RegExp(
      `([^]*)((?:^|(?<=}\\s*))(?:[\\w.]+,\\s)*${selector}(?:,\\s[\\w.]+)*\\s*{[^}]*})([^]*)`
    );
    return cssString.match(regex);
  }

  /**
   * Insert a property + value into a css rule string before the closing bracket
   * @param {string} cssRuleString - The CSS rule string to modify
   * @param {string} property - The CSS property to insert
   * @param {string} value - The value for the property
   * @param {boolean} [important=false] - Whether to add !important flag
   * @returns {string} Modified CSS rule string
   */
  static insertProperty(cssRuleString, property, value, important = false) {
    const importantFlag = important ? ' !important' : '';
    const regex = /([^}]*)(})/;
    return cssRuleString.replace(
      regex,
      `$1${property}: ${value}${importantFlag}; $2`
    );
  }

  /**
   * Insert a basic css rule with a selector, property, and value into the front of a css string
   * @param {string} cssString - The CSS string to prepend to
   * @param {string} selector - The CSS selector for the new rule
   * @param {string} property - The CSS property for the new rule
   * @param {string} value - The value for the property
   * @param {boolean} [important=false] - Whether to add !important flag
   * @returns {string} Modified CSS string
   */
  static insertRule(cssString, selector, property, value, important = false) {
    const importantFlag = important ? ' !important' : '';
    return `${selector} { ${property}: ${value}${importantFlag}; } ${cssString.trim()}`;
  }

  /**
   * Check if a css rule contains only whitespace between the brackets
   * @param {string} cssRuleString - The CSS rule string to check
   * @returns {boolean} True if the rule is empty
   */
  static isRuleEmpty(cssRuleString) {
    const regex = /[^{]*{\s*}/;
    return Boolean(cssRuleString.match(regex));
  }

  /**
   * Update a property value in a css rule string. If not found, inserts the first property from properties param
   * @param {string} cssRuleString - The CSS rule string to modify
   * @param {[string]} properties - Array of CSS properties to update. Updates the first one found
   * @param {string} newValue - The new value for the property
   * @param {boolean} [important=false] - Whether to add !important flag
   * @returns {string} Modified CSS rule string
   */
  static updatePropertyValue(cssRuleString, properties, newValue, important = false) {
    const propertyMatch = this.findProperty(cssRuleString, properties);
    let result;
    if (propertyMatch) {
      const [, ruleBefore, matchedProperty, , ending, ruleAfter] = propertyMatch;
      // if ending does not start with a semicolon or space, add a space
      const correctedEnding = ending.match(/^\s+|^;/) ? ending : ` ${ending}`;
      result = `${ruleBefore}${matchedProperty}: ${newValue}${correctedEnding}${ruleAfter}`;
    } else {
      result = this.insertProperty(cssRuleString, properties[0], newValue, important);
    }
    return result;
  }

  /**
   * Remove a property from a css rule string
   * @param {string} cssRuleString - The CSS rule string to modify
   * @param {[string]} properties - Array of CSS properties to remove. Removes the first one found
   * @returns {string} Modified CSS rule string
   */
  static removeProperty(cssRuleString, properties) {
    const regex = new RegExp(`(${properties.join('|')})\\s*:\\s*([^;\\s!]*)(\\s+!important;|;)\\s*`);
    return cssRuleString.replace(regex, '');
  }
}

export default CssManipulator;
