import { Controller } from 'stimulus';

export default class extends Controller {
  fetch(event) {
    event.preventDefault();
    event.stopPropagation();
    const method = event.target.dataset.method || 'POST';
    fetch(event.target.href, {
      method,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    }).then(response => response.text())
      .then((html) => {
        this.element.insertAdjacentHTML('beforebegin', html);
        this.newTarget = this.element.previousElementSibling;
        if (this.newTarget) {
          this.newTarget.classList.add('flash-opacity');
        }
        this.element.remove();
      });
  }
}
