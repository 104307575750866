import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['recipientsField'];

  connect() {
    this.recipientsFieldTarget.addEventListener('input', () => {
      this.recipientsFieldTarget.setCustomValidity('');
      this.recipientsFieldTarget.reportValidity();
    });
  }

  validEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return emailRegex.test(email);
  }

  validateRecipients() {
    const recipients = this.recipientsFieldTarget.value.split(',').map(email => email.trim()).filter(email => email.length > 0);
    if (recipients.length === 0) {
      this.recipientsFieldTarget.setCustomValidity('Please enter at least one email address');
    } else {
      const invalidEmails = recipients.filter(email => !this.validEmail(email));
      const alertMessage = invalidEmails.map(email => `"${email}" is not a valid email address`).join('\n');
      this.recipientsFieldTarget.setCustomValidity(alertMessage);
    }
    this.recipientsFieldTarget.reportValidity();
  }
}
