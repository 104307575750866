import Rails from '@rails/ujs';
import UploadsController from '../uploads_controller';

function log(message) {
  console.log(`${new Date().getTime()}: ${message}`);
}

export default class extends UploadsController {
  attach(id) {
    const signedBlobIds = this.element.querySelectorAll('input[name$="[upload_attachments]"][type="hidden"]');
    const signedBlobId = signedBlobIds[id - 1];
    const linkName = this.element.getAttribute('data-link-name');
    const uploadData = { upload: { signedBlobId: signedBlobId.value, linkName } };
    let attachUrl = this.element.getAttribute('data-attach-url');
    if (typeof (attachUrl) === 'undefined' || attachUrl == null || attachUrl === '') { attachUrl = 'attach'; }

    fetch(attachUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(uploadData)
    }).then((response) => {
      log(`fetch response (${id}, ${response.ok})`);
      if (!response.ok) {
        alert(`Error: (${response.status}, ${response.statusText}, ${response.url})`);
        throw response;
      }
      return response.json();
    }).then((data) => {
      log(`fetch success (${id})`);
      if (data.redirect_url) {
        window.location = data.redirect_url;
      } else {
        window.location = window.location;
      }
    }).catch((error) => {
      console.log(`fetch error (${id})`, error);
      this.uploadedFileCount -= 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    });
  }

  connect() {
    log('connect');
  }

  end({ detail: { id } }) {
    this.uploadedFileCount += 1;
    this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
    this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    this.attach(id);
  }

  select() {
    log('select');
    const selectedFiles = this.inputTarget.files;
    this.addFilesButtonTarget.hidden = true;
    this.uploadedFileCount = 0;
    this.selectedFileCount = selectedFiles.length;
    this.progressTarget.innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    this.progressTarget.hidden = false;

    Rails.fire(this.element.querySelector('form'), 'submit');
  }
}
