import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'billingField', 'billingFieldToggle', 'billingFieldWrapper', 'billingEmail1', 'billingEmail2', 'billingEmail3', 'billingEmail4',
    'breedTagsContainer', 'categorySelect', 'clerkingSystemEmailsContainer', 'clerkingSystemEmail2', 'clerkingSystemEmail3', 'clerkingSystemEmail4',
    'contactFieldsContainer', 'contactCity', 'contactCountry', 'contactEmail1', 'contactEmail2', 'contactEmail3', 'contactEmail4', 'contactFax', 'contactName',
    'contactPhone1', 'contactPhone2', 'contactPhone3', 'contactRanchName', 'contactState', 'contactStreetAddress',
    'contactWebsite', 'contactZip', 'editingPriceInput', 'facilitiesSearchModalContainer', 'facilityFieldsWrapper', 'facilityFieldSelectWrapper',
    'physicalMediaOptionsContainer', 'onSiteCustomerInputWrapper', 'onSiteInput', 'onSiteNotesInputWrapper', 'onSitePricingInputWrapper',
    'onSiteTvOperatorInputWrapper', 'physicalMediaInput', 'shootingPriceInput', 'shootingPriceTypeInput', 'videographyNotes', 'videographyVideoCatalogPageInput'
  ];

  connect() {
    this.wrapperClass = 'nested-fields';
    this.addEventListeners();
    this.toggleBillingFields();
    this.toggleBreedTagsContainer();
    this.toggleHowManyCopies();
    this.updateOnSiteFields();
  }

  addAdditionalBillingEmail(event) {
    event.preventDefault();
    if (this.billingEmail2Target.closest('.form-group').hidden) {
      this.billingEmail2Target.closest('.form-group').hidden = false;
    } else if (this.billingEmail3Target.closest('.form-group').hidden) {
      this.billingEmail3Target.closest('.form-group').hidden = false;
    } else if (this.billingEmail4Target.closest('.form-group').hidden) {
      this.billingEmail4Target.closest('.form-group').hidden = false;
      event.target.parentElement.parentElement.hidden = true;
    }
  }

  addAdditionalClerkingSystemEmail(event) {
    event.preventDefault();
    if (this.clerkingSystemEmail2Target.hidden) {
      this.clerkingSystemEmail2Target.hidden = false;
    } else if (this.clerkingSystemEmail3Target.hidden) {
      this.clerkingSystemEmail3Target.hidden = false;
    } else if (this.clerkingSystemEmail4Target.hidden) {
      this.clerkingSystemEmail4Target.hidden = false;
      event.target.parentElement.parentElement.hidden = true;
    }
  }

  addAdditionalContactEmail(event) {
    event.preventDefault();
    if (this.contactEmail2Target.closest('.form-group').hidden) {
      this.contactEmail2Target.closest('.form-group').hidden = false;
    } else if (this.contactEmail3Target.closest('.form-group').hidden) {
      this.contactEmail3Target.closest('.form-group').hidden = false;
    } else if (this.contactEmail4Target.closest('.form-group').hidden) {
      this.contactEmail4Target.closest('.form-group').hidden = false;
      event.target.parentElement.parentElement.hidden = true;
    }
  }

  addAdditionalDay(event) {
    event.preventDefault();
    this.dateTimeInputs = event.target.parentElement.querySelectorAll('.flatpickr-input');
    this.dateTimeInput = this.dateTimeInputs[this.dateTimeInputs.length - 1];
    const templateId = event.target.getAttribute('data-template');
    const content = document.getElementById(templateId).innerHTML;
    event.target.insertAdjacentHTML('beforebegin', content.replace(/new_record/g, new Date().getTime()));
    setTimeout(() => {
      if (this.dateTimeInput) {
        this.newDate = new Date(this.dateTimeInput.value);
      } else {
        this.newDate = new Date(event.target.closest('.card-body').querySelector('.flatpickr-input').value);
      }
      if (this.newDate.toString() !== 'Invalid Date') {
        this.dateTimeInputs = event.target.parentElement.querySelectorAll('.flatpickr-input');
        this.dateTimeInput = this.dateTimeInputs[this.dateTimeInputs.length - 1];
        this.newDate.setDate(this.newDate.getDate() + 1); // Add a day
        this.dateTimeInput.value = this.formatDate(this.newDate);
        this.dateTimeInput.dispatchEvent(new Event('change'));
      }
      event.target.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  addEventListeners() {
    document.querySelector('#booking_facility_id').addEventListener('change', this.updateFacilityInputs.bind(this));
    if (document.querySelector('#booking_videography_service_attributes_editor')) {
      document.querySelector('#booking_videography_service_attributes_editor').addEventListener('change', this.updateVideographyPricing.bind(this));
    }
    if (document.querySelector('#booking_videography_service_attributes_videographer')) {
      document.querySelector('#booking_videography_service_attributes_videographer').addEventListener('change', this.updateVideographyPricing.bind(this));
    }
    if (document.querySelector('#booking_domain_id')) {
      document.querySelector('#booking_domain_id').addEventListener('change', this.handleDomainChange.bind(this));
    }
    this.categorySelectTarget.addEventListener('change', this.toggleBreedTagsContainer.bind(this));
  }

  addNestedForm(event) {
    event.preventDefault();
    this.wrapper = event.target.closest('.card');
    if (this.wrapper.querySelector('.nested-input-fields')) {
      this.wrapper.querySelector('.nested-input-fields').hidden = false;
    } else {
      const templateId = event.target.getAttribute('data-template');
      const content = document.getElementById(templateId).innerHTML;
      event.target.insertAdjacentHTML('beforebegin', content);
      $('[data-toggle="popover"]').popover();
    }
    if (event.target.innerHTML === 'Add Broadcast Service') {
      this.updateOnSiteFields();
    } else if (event.target.innerHTML === 'Add Videography Service') {
      document.querySelector('#booking_videography_service_attributes_editor').addEventListener('change', this.updateVideographyPricing.bind(this));
      document.querySelector('#booking_videography_service_attributes_videographer').addEventListener('change', this.updateVideographyPricing.bind(this));
    }
    event.target.innerHTML = event.target.innerHTML.replace('Add', 'Remove');
    event.target.classList.add('text-danger');
    event.target.setAttribute('data-action', event.target.getAttribute('data-action').replace('#addNestedForm', '#removeNestedForm'));
    event.target.closest('.card').scrollIntoView({ behavior: 'smooth' });
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  handleDomainChange = (event) => {
    fetch(`/admin/search/domain_json?id=${event.target.value}`)
      .then((response) => {
        if (!response.ok) { throw response; }
        return response.json();
      })
      .then((data) => {
        this.contactCityTarget.value = data.city;
        this.contactCountryTarget.value = data.country;
        this.contactEmail1Target.value = data.contact_email_1;
        this.contactEmail2Target.value = data.contact_email_2;
        this.contactEmail3Target.value = data.contact_email_3;
        this.contactEmail4Target.value = data.contact_email_4;
        this.contactFaxTarget.value = data.fax;
        this.contactNameTarget.value = data.contact;
        this.contactPhone1Target.value = data.phone_1;
        this.contactPhone2Target.value = data.phone_2;
        this.contactPhone3Target.value = data.phone_3;
        this.contactRanchNameTarget.value = data.title;
        this.contactStateTarget.value = data.state;
        this.contactStreetAddressTarget.value = data.street_1;
        this.contactWebsiteTarget.value = data.url;
        this.contactZipTarget.value = data.zip;
      })
      .catch((error) => {
        console.error(error);
        this.h3 = document.createElement('h3');
        this.h3.classList.add('col', 'text-danger', 'text-center');
        this.h3.id = 'booking_domain_attributes_id';
        this.h3.innerHTML = 'Failed to load form';
        this.contactFieldsContainerTarget.innerHTML = this.h3.outerHTML;
      });
    fetch(`/admin/search/facilities_search_modal?domain_id=${event.target.value}`)
      .then((response) => {
        if (!response.ok) { throw response; }
        return response.text();
      })
      .then((html) => {
        this.facilitiesSearchModalContainerTarget.innerHTML = html;
      });
  }

  removeAdditionalDay(event) {
    event.preventDefault();
    const wrapper = event.target.closest('.nested-fields');
    if (wrapper.querySelector("input[name*='_destroy']")) {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.hidden = true;
    } else {
      wrapper.remove();
    }
  }

  removeNestedForm(event) {
    event.preventDefault();
    this.wrapper = event.target.closest('.card');
    this.wrapper.querySelector("input[name*='_destroy']").value = 1;
    if (this.wrapper.querySelector('.template-nested-input-fields')) { this.wrapper.querySelector('.template-nested-input-fields').remove(); }
    if (this.wrapper.querySelector('.nested-input-fields')) { this.wrapper.querySelector('.nested-input-fields').hidden = true; }
    event.target.innerHTML = event.target.innerHTML.replace('Remove', 'Add');
    event.target.classList.remove('text-danger');
    event.target.setAttribute('data-action', event.target.getAttribute('data-action').replace('#removeNestedForm', '#addNestedForm'));
    event.target.closest('.card').scrollIntoView({ behavior: 'smooth' });
  }

  setCustomerProvidedTvPricing(event) {
    if (event.target.checked) {
      this.onSitePricingInputWrapperTarget.querySelector('input').value = '700';
    } else {
      this.onSitePricingInputWrapperTarget.querySelector('input').value = '1000';
    }
  }

  toggleBillingFields() {
    this.billingFieldWrapperTarget.hidden = this.billingFieldToggleTarget.checked;
    this.billingFieldTargets.forEach((field) => {
      field.disabled = this.billingFieldToggleTarget.checked;
    });
  }

  toggleBreedTagsContainer() {
    if (this.categorySelectTarget.value === 'Cattle') {
      this.breedTagsContainerTarget.hidden = false;
    } else {
      document.querySelectorAll('input[name="booking[breed_tag_ids][]"]').forEach((field) => { field.checked = false; });
      this.breedTagsContainerTarget.hidden = true;
    }
  }

  toggleClerkingSystemEmailsContainer() {
    if (document.querySelector('#booking_clerking_system_access').checked) {
      this.clerkingSystemEmailsContainerTarget.hidden = false;
    } else {
      this.clerkingSystemEmailsContainerTarget.hidden = true;
    }
  }

  toggleFacilityInputs(event) {
    event.preventDefault();
    if (event.target.innerHTML === 'Provide new facility info') {
      event.target.innerHTML = 'Select existing facility';
    } else {
      event.target.innerHTML = 'Provide new facility info';
    }
    document.querySelectorAll('[id^="booking_facility"]').forEach((field) => { field.value = null; });
    document.getElementById('facility_label').value = 'Select facility...';
    this.facilityFieldsWrapperTarget.hidden = !this.facilityFieldsWrapperTarget.hidden;
    this.facilityFieldSelectWrapperTarget.hidden = !this.facilityFieldSelectWrapperTarget.hidden;
  }

  toggleHowManyCopies() {
    if (this.hasPhysicalMediaInputTarget && this.hasPhysicalMediaOptionsContainerTarget) {
      if (this.physicalMediaInputTarget.checked) {
        this.physicalMediaOptionsContainerTarget.hidden = false;
      } else {
        this.physicalMediaOptionsContainerTarget.hidden = true;
      }
    }
  }

  updateFacilityInputs() {
    this.facilityFieldsWrapperTarget.querySelectorAll('input, select').forEach((field) => { field.value = null; });
    if (document.querySelector('#booking_facility_id').value) {
      this.facilityFieldsWrapperTarget.hidden = true;
    } else {
      this.facilityFieldsWrapperTarget.hidden = false;
    }
  }

  updateOnSiteFields() {
    if (!this.hasOnSiteInputTarget) { return; } // Do nothing if no broadcast service

    if (this.onSiteInputTarget.checked) {
      this.onSiteNotesInputWrapperTarget.hidden = false;
      this.onSitePricingInputWrapperTarget.hidden = false;
      this.onSiteTvOperatorInputWrapperTarget.hidden = false;
      this.onSiteCustomerInputWrapperTarget.hidden = false;
    } else {
      this.onSiteNotesInputWrapperTarget.hidden = true;
      this.onSiteNotesInputWrapperTarget.querySelector('textarea').value = '';
      this.onSitePricingInputWrapperTarget.hidden = true;
      this.onSiteTvOperatorInputWrapperTarget.hidden = true;
      this.onSiteTvOperatorInputWrapperTarget.querySelector('#booking_broadcast_service_attributes_on_site_tv_operator').checked = false;
      this.onSiteCustomerInputWrapperTarget.hidden = true;
      this.onSiteCustomerInputWrapperTarget.querySelector('#booking_broadcast_service_attributes_on_site_customer_tvs').checked = false;
    }
  }

  updateVideographyPricing() {
    const videographer = document.querySelector('#booking_videography_service_attributes_videographer').value;
    const editor = document.querySelector('#booking_videography_service_attributes_editor').value;

    if (videographer === 'DVAuction' && editor === 'DVAuction') {
      this.editingPriceInputTarget.value = '20';
      this.shootingPriceInputTarget.value = '500';
      this.shootingPriceTypeInputTarget.value = 'per day';
      this.videographyVideoCatalogPageInputTarget.checked = true;
    } else if (videographer === 'Customer' && editor === 'DVAuction') {
      this.editingPriceInputTarget.value = '40';
      this.shootingPriceInputTarget.value = '';
      this.shootingPriceTypeInputTarget.value = '';
      this.videographyVideoCatalogPageInputTarget.checked = true;
    } else if (videographer === 'Customer' && editor === 'Customer') {
      this.editingPriceInputTarget.value = '10';
      this.shootingPriceInputTarget.value = '';
      this.shootingPriceTypeInputTarget.value = '';
      this.videographyNotesTarget.value = '$10 editing fee for customer handling / re-encoding';
      this.videographyVideoCatalogPageInputTarget.checked = false;
    } else if (videographer === 'Ideal' && editor === 'Ideal') {
      this.editingPriceInputTarget.value = '0';
      this.shootingPriceInputTarget.value = '';
      this.shootingPriceTypeInputTarget.value = '';
      this.videographyVideoCatalogPageInputTarget.checked = true;
    }
  }
}
