import { Controller } from 'stimulus';
import CssManipulator from '../../helpers/css_manipulator';

function expandHexColor(hex) {
  return hex.length === 4 ? `#${hex.slice(1).split('').map(x => x + x).join('')}` : hex;
}

export default class extends Controller {
  static targets = [
    'colorPicker',
    'colorPickerContainer',
    'colorPickerValueField',
    'key',
    'styleFormContainer',
    'textArea',
    'textField'
  ];

  connect() {
    this.updateFormFromKey();
  }

  initializeColorPicker() {
    this.updateColorPicker('#c0392b');
    const colorPicker = this.colorPickerTarget;
    const colorPickerValueField = this.colorPickerValueFieldTarget;
    colorPickerValueField.value = this.colorPickerTarget.value;
    colorPicker.addEventListener('input', this.updateColorPickerValueField, false);
    colorPicker.addEventListener('change', this.updateColorPickerValueField, false);
    colorPicker.select();
    colorPickerValueField.addEventListener('input', () => {
      colorPicker.value = colorPickerValueField.value;
    });
  }

  initializeStyleContent(selector, properties, formValue) {
    const cssString = this.textAreaTarget.value.trim();
    const currentPropertyValue = CssManipulator.findCssPropertyValue(cssString, selector, properties);
    if (currentPropertyValue) {
      this.updateColorPicker(currentPropertyValue);
    } else {
      this.textAreaTarget.value = CssManipulator.updateCss(cssString, selector, properties, formValue, true);
    }
  }

  removeStyleContent(selector, properties) {
    const cssString = this.textAreaTarget.value.trim();
    this.textAreaTarget.value = CssManipulator.removeCss(cssString, selector, properties);
  }

  toggleStyleContentColorPickerForm(event) {
    const ruleSelector = event.target.dataset.cssSelector;
    const propertyNames = JSON.parse(event.target.dataset.cssProperties);
    if (event.target.checked) {
      this.initializeColorPicker();
      this.initializeStyleContent(ruleSelector, propertyNames, this.colorPickerTarget.value);
      this.colorPickerContainerTarget.hidden = false;
    } else {
      this.colorPickerContainerTarget.hidden = true;
      this.removeStyleContent(ruleSelector, propertyNames);
    }
  }

  updateColorPicker(value) {
    const hexValue = expandHexColor(value);
    this.colorPickerTarget.value = hexValue;
    this.colorPickerValueFieldTarget.value = hexValue;
  }

  updateColorPickerValueField() {
    const colorValue = document.getElementById('header-color-picker-value');
    colorValue.value = document.getElementById('header-color-picker').value;
  }

  updateFormFromKey() {
    if (this.keyTarget.value === 'subsite_logo') {
      document.getElementById('modal-button').hidden = false;
      this.styleFormContainerTarget.hidden = true;
      this.textAreaTarget.hidden = true;
      this.textAreaTarget.disabled = true;
      this.textFieldTarget.hidden = false;
      this.textFieldTarget.disabled = false;
    } else if (this.keyTarget.value === 'style') {
      document.getElementById('modal-button').hidden = true;
      this.styleFormContainerTarget.hidden = false;
      this.textAreaTarget.hidden = false;
      this.textAreaTarget.disabled = false;
      this.textFieldTarget.hidden = true;
      this.textFieldTarget.disabled = true;
    } else {
      document.getElementById('modal-button').hidden = true;
      this.styleFormContainerTarget.hidden = true;
      this.textAreaTarget.hidden = false;
      this.textAreaTarget.disabled = false;
      this.textFieldTarget.hidden = true;
      this.textFieldTarget.disabled = true;
    }
  }

  updateStyleContentPropertyValue(event) {
    const cssString = this.textAreaTarget.value.trim();
    const newValue = event.target.value;
    const ruleSelector = event.target.dataset.cssSelector;
    const propertyNames = JSON.parse(event.target.dataset.cssProperties);
    this.textAreaTarget.value = CssManipulator.updateCss(cssString, ruleSelector, propertyNames, newValue, true);
  }
}
